import { MouseEvent } from 'react';
import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import moment from 'moment';
import styles from './ConfirmBillingModal.module.less';
import { useState } from 'react';
import { FormInstance } from 'antd/es';

const dateFormat = 'YYYY-MM-DD';

interface ConfirmBillingModalProps {
    readonly formRef: FormInstance<any>;
    readonly testId?: string;
    readonly isLoading: boolean;
    readonly isVisible: boolean;
    readonly onCancel: (e: MouseEvent<HTMLElement>) => void;
    readonly onSubmitForm: Function;
}

function ConfirmBillingModal({
    formRef,
    onCancel,
    isLoading,
    isVisible,
    onSubmitForm,
    testId = 'ConfirmBillingModal',
}: ConfirmBillingModalProps) {
    const [isValidDate, setIsValidDate] = useState<boolean>(true);

    const dateValidation = () => {
        const { fromDate, toDate } = formRef.getFieldsValue();

        const isBetweenFromAndTo =
            moment(toDate).isBefore(fromDate) ||
            (toDate && moment(fromDate).isAfter(toDate));

        setIsValidDate(!isBetweenFromAndTo);
    };

    return (
        <Modal
            open={isVisible}
            width={400}
            title={<strong>Select Dates</strong>}
            onCancel={onCancel}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            data-testid={`${testId}CancelButton`}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={isLoading}
                            disabled={!isValidDate}
                            type="primary"
                            data-testid={`${testId}SubmitButton`}
                            onClick={() =>
                                onSubmitForm(formRef.getFieldsValue())
                            }
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form layout="vertical" form={formRef}>
                <Form.Item
                    name={'fromDate'}
                    required
                    label="From Date"
                    rules={[
                        { required: true, message: 'Please select From Date' },
                    ]}
                >
                    <DatePicker
                        className={styles.DateInput}
                        format={dateFormat}
                        disabledDate={(current) => current >= moment()}
                        clearIcon={false}
                        suffixIcon={false}
                        onChange={dateValidation}
                        value={moment().startOf('day')}
                    />
                </Form.Item>
                <Form.Item
                    name={'toDate'}
                    required
                    label="To Date"
                    rules={[
                        { required: true, message: 'Please select To Date' },
                    ]}
                >
                    <DatePicker
                        className={styles.DateInput}
                        clearIcon={false}
                        suffixIcon={false}
                        format={dateFormat}
                        value={moment().endOf('day')}
                        disabledDate={(current) => current >= moment()}
                        onChange={dateValidation}
                    />
                </Form.Item>
                {!isValidDate && (
                    <span style={{ color: 'red' }}>
                        The 'To' date cannot be before the 'From' date.
                    </span>
                )}
            </Form>
        </Modal>
    );
}

export default ConfirmBillingModal;
