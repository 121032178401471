import ApiService from './ApiService';
import { API_ADMIN } from '../core/CcxEnv';

// constants
const API_URL = API_ADMIN;

export default class DownloadingReportsService extends ApiService {
    /**
     * @params type.
     * @params dateRange
     */
    static async getBillingReport(type: string, dateRange: any): Promise<any> {
        const response = await fetch(
            `${API_URL}/datastores/billing/usage/${type}?from=${dateRange.fromDate}&to=${dateRange.toDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/csv',
                },
            }
        );

        if (!response.ok) {
            throw new Error();
        }

        return await response.blob();
    }

    static async getDatastoreReport(): Promise<any> {
        const response: any = await fetch(`${API_URL}/datastores/csv`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/csv',
            },
        });

        if (!response.ok) {
            throw new Error();
        }

        return response.blob();
    }

    static async getUsersReport(): Promise<any> {
        const response: any = await fetch(`${API_URL}/users/csv`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/csv',
            },
        });
        if (!response.ok) {
            throw new Error();
        }

        return await response.blob();
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
