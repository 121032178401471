import { Row, Col, notification } from 'antd';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import useCredentials from '../../../core/hooks/useCredentials';
import AuthorizationService from '../../../services/AuthorizationService';
import Credentials from '../../../types/Credentials';
import AppEmpty from '../../AppEmpty';
import AppTable from '../../AppTable';
import ButtonModalForm from '../../ButtonModalForm';
import DotLabelStatus from '../../ccx/common/DotLabelStatus';
import CcxIconCheckCircleTwoTone from '../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import LazyLoader from '../../LazyLoader';
import AuthorizationModal from '../AuthorizationModal';
import styles from './Authorization.module.less';
import { CredentialsActions } from './CredentialsActions';

export function Authorization({
    testId = 'Authorization',
}: CcxComponentProps): ReactElement {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [extra, setExtra] = useState<string>(
        'Leave empty for never expiring tokens'
    );
    const [newCredentials, setNewCredentials] = useState<Credentials>();

    const { credentials, refresh, loading } = useCredentials();
    const [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        if (credentials) {
            setTableData(credentials);
        }
    }, [credentials]);

    const onChange = (e: any) => {
        setExtra(
            e
                ? 'Time displayed in hours. Leave empty for never expiring tokens'
                : 'Leave empty for never expiring tokens'
        );
    };

    const doApiRequest = async ({ description, expirationTime }: any) => {
        try {
            const response = await AuthorizationService.addCredentials({
                description,
                expirationTime,
            });

            setNewCredentials(new Credentials(response));
            setShowResult(true);

            notification.open({
                message: 'Create credentials',
                description: `New credentials successfully created!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Create credentials',
                description: `There was an error creating your credentials. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            throw e;
        }
    };

    const fieldsSetup = [
        {
            name: ['description'],
            testId: 'AuthorizationDescription',
            label: 'Description',
            placeholder: 'What the token will be used for',
        },
        {
            name: ['expirationTime'],
            testId: 'AuthorizationExpirationTime',
            type: 'number',
            label: 'Expiration time',
            placeholder: 'After what time the token will expire',
            align: 'left',
            extra,
            onChange,
        },
    ];

    let StatusComponent = null;

    const tableColumns = [
        {
            title: 'Client ID',
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (value: string, record: any) => {
                if (record?.isRevoked()) {
                    StatusComponent = (
                        <DotLabelStatus
                            type="error"
                            label={record?.getStatusText()}
                            testId={`${testId}StatusComponent`}
                        />
                    );
                } else if (record?.isDisabled()) {
                    StatusComponent = (
                        <DotLabelStatus
                            type="disabled"
                            label={record?.getStatusText()}
                            testId={`${testId}StatusComponent`}
                        />
                    );
                } else if (record?.isExpired()) {
                    StatusComponent = (
                        <DotLabelStatus
                            type="disabled"
                            label={record?.getStatusText()}
                            testId={`${testId}StatusComponent`}
                        />
                    );
                } else if (record?.isActive()) {
                    StatusComponent = (
                        <DotLabelStatus
                            type="ok"
                            label={record?.getStatusText()}
                            testId={`${testId}StatusComponent`}
                        />
                    );
                } else {
                    StatusComponent = (
                        <DotLabelStatus
                            type="normal"
                            label={record?.getStatusText()}
                            testId={`${testId}StatusComponent`}
                        />
                    );
                }
                return StatusComponent;
            },
        },
        {
            title: 'Expires in',
            key: 'expiresAt',
            render: (value: string, record: any) => {
                if (record.expiresAt) {
                    const now = new Date();
                    const expiry = new Date(record.expiresAt);

                    return expiry > now
                        ? moment.utc(record?.expiresAt).fromNow()
                        : 'Expired';
                }
                return 'Never';
            },
        },
        {
            title: 'Created',
            key: 'createdAt',
            render: (value: string, record: any) => {
                return moment.utc(record?.createdAt).fromNow();
            },
        },
        {
            title: 'Last Used',
            key: 'lastUsedAt',
            dataIndex: 'lastUsedAt',
            render: (value: string, record: any) => {
                return record.lastUsedAt
                    ? `${record.lastUsedAt} from ${record.lastUsedFrom}`
                    : 'Never';
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actions',
            key: 'pdf',
            render: (text: string, record: any) => (
                <CredentialsActions credentials={record} refresh={refresh} />
            ),
        },
    ];

    if (loading) {
        return <LazyLoader type="row" testId={`${testId}Loader`} />;
    }

    return (
        <section data-testid={`${testId}`}>
            <Row justify="space-between" data-testid={`${testId}HeaderRow`}>
                <Col>
                    <strong>OAuth2 credentials</strong>
                </Col>
                <Col>
                    <ButtonModalForm
                        title="Create credentials"
                        buttonText="Create credentials"
                        buttonType="primary"
                        onSubmit={doApiRequest}
                        onSuccess={refresh}
                        fields={fieldsSetup}
                        submitText="Create"
                        formLayout="vertical"
                    ></ButtonModalForm>

                    <AuthorizationModal
                        credentials={newCredentials}
                        show={showResult}
                        setShow={setShowResult}
                    ></AuthorizationModal>
                </Col>
            </Row>

            {tableData ? (
                <AppTable
                    className={styles.AuthorizationTable}
                    columns={tableColumns}
                    testId={`${testId}Table`}
                    data={tableData}
                    rowKey="subID"
                    expandable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 50,
                    }}
                />
            ) : (
                <AppEmpty message="There are no credentials to display" />
            )}
        </section>
    );
}
