import React, { Suspense, ReactElement } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LazyLoader from './components/LazyLoader';
import DeploymentCompare from './components/deployments/compare/DeploymentCompare';
import VpcPeers from './components/vpc/VpcPeers';
import Account from './components/account/Account';
import Projects from './components/projects/Projects';
import DataStore from './components/projects/dataStores/DataStore';
import AddService from './components/deployments/services/AddService';
import AdminTables from './components/admin/AdminTables';
import Error404Page from './components/errors/Error404Page';
import { useAppSelector } from './redux/hooks';

/**
 * Routes definitions
 */

function AppRoute(): ReactElement {
    const { user: userReducer } = useAppSelector((state) => state.user);

    return (
        <Suspense fallback={<LazyLoader />}>
            <Switch>
                <Route path="/" exact={true}>
                    {/* <Home /> */}
                    {/* @TODO: Remove the redirection */}
                    <Redirect to="/projects/default/data-stores" />
                </Route>

                <Route path="/onboarding">
                    <Redirect to="/" />
                </Route>

                <Route path="/admin" exact={true}>
                    <AdminTables />
                </Route>
                <Route path="/account" exact={true}>
                    <Account />
                </Route>

                <Route path="/projects/:projectUuid/:activeTab" exact={true}>
                    <Projects />
                </Route>

                <Route
                    path="/projects/:projectUuid/vpcs/:vpcUuid/peerings"
                    exact={true}
                >
                    <VpcPeers />
                </Route>

                <Route
                    path="/projects/:projectUuid/vpcs/:vpcUuid/peerings/add"
                    exact={true}
                >
                    <VpcPeers />
                </Route>

                <Route
                    path="/projects/:projectUuid/services/new/:service"
                    exact={true}
                >
                    <AddService user={userReducer} />
                </Route>

                <Route
                    path="/projects/:projectUuid/data-stores/add"
                    exact={true}
                >
                    <Suspense fallback={<LazyLoader />}>
                        <AddService user={userReducer} />
                    </Suspense>
                </Route>

                <Route
                    path="/projects/:projectUuid/data-stores/:dataStoreUuid/:activeTab/:tab"
                    exact={true}
                >
                    <DataStore />
                </Route>

                <Route
                    path="/projects/:projectUuid/data-stores/:dataStoreUuid/:activeTab"
                    exact={true}
                >
                    <DataStore />
                </Route>

                <Route
                    path="/projects/:projectUuid/data-stores/:dataStoreUuid/compare/:tab"
                    exact={true}
                >
                    <Suspense fallback={<LazyLoader />}>
                        <DeploymentCompare />
                    </Suspense>
                </Route>

                {/* No match */}
                <Route exact={true} path="*">
                    <Error404Page />
                </Route>
            </Switch>
        </Suspense>
    );
}

export default AppRoute;
