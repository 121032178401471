import { useState } from 'react';
import { Button, Dropdown, Menu, message } from 'antd';
import {
    DatabaseOutlined,
    FileExcelOutlined,
    FileTextOutlined,
    UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import DownloadingReportsService from '../../services/DownloadingReportsService';
import ConfirmBillingModal from './ConfirmBillingModal';
import { useForm } from 'antd/lib/form/Form';
import { FormInstance } from 'antd/es';

function DownloadButton() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmationDate, setConfirmationDate] = useState<boolean>(false);
    const [form]: [FormInstance<any>] = useForm();

    const onClickBillingReport = () => {
        let currentDate = moment();

        let firstDayOfMonth = moment().startOf('month');

        form.setFieldsValue({
            fromDate: firstDayOfMonth,
            toDate: currentDate,
        });
        setConfirmationDate(true);
    };

    const getBillingReportCSV = async (values: any) => {
        setIsLoading(true);
        if (!values.fromDate || !values.toDate) {
            form.validateFields();
            return;
        }
        const reportDate = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD').toString(),
            toDate: moment(values.toDate).format('YYYY-MM-DD').toString(),
        };
        try {
            const report = await DownloadingReportsService.getBillingReport(
                'csv',
                reportDate
            );
            const fileName: string = `billing-report-${values.fromDate}-${values.toDate}`;
            downloadReport(report, fileName);
        } catch (e) {
            console.log(e);
            message.error('Error: Downloading the billing report failed.');
            setIsLoading(false);
        }
        setConfirmationDate(false);
        form.resetFields();
    };

    const getDatastoreReportCSV = async () => {
        setIsLoading(true);
        try {
            const report = await DownloadingReportsService.getDatastoreReport();
            const fileName: string = 'datastore';
            downloadReport(report, fileName);
        } catch (e) {
            message.error('Error: Downloading the datastore list failed.');
            setIsLoading(false);
        }
    };

    const getUsersReportCSV = async () => {
        setIsLoading(true);
        try {
            const report = await DownloadingReportsService.getUsersReport();
            const fileName: string = 'users';
            downloadReport(report, fileName);
        } catch {
            message.error('Error: Downloading users list failed');
            setIsLoading(false);
        }
    };

    const downloadReport = async (reportFile: any, fileName: string) => {
        const url = window.URL.createObjectURL(reportFile);
        const reportFileLink = document.createElement('a');
        reportFileLink.href = url;
        reportFileLink.download = `${fileName}.csv`;
        document.body.appendChild(reportFileLink);
        reportFileLink.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
    };

    return (
        <>
            <Dropdown
                data-testid="DownloadActions"
                overlay={
                    <Menu mode="horizontal">
                        <Menu.Item
                            onClick={() => onClickBillingReport()}
                            key="billing"
                        >
                            <FileTextOutlined /> Billing Report
                        </Menu.Item>
                        <Menu.Item
                            key="datastore"
                            onClick={() => getDatastoreReportCSV()}
                        >
                            <DatabaseOutlined /> Datastore Report
                        </Menu.Item>
                        <Menu.Item
                            key="users"
                            onClick={() => getUsersReportCSV()}
                        >
                            <UserOutlined /> Users
                        </Menu.Item>
                    </Menu>
                }
                placement="bottomRight"
                trigger={['click']}
            >
                <Button loading={isLoading} icon={<FileExcelOutlined />}>
                    Exports
                </Button>
            </Dropdown>
            <ConfirmBillingModal
                isLoading={isLoading}
                isVisible={confirmationDate}
                onCancel={() => {
                    setConfirmationDate(false);
                    form.resetFields();
                }}
                onSubmitForm={getBillingReportCSV}
                formRef={form}
            />
        </>
    );
}

export default DownloadButton;
