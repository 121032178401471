import ApiService from './ApiService';
import { API_ADMIN } from '../core/CcxEnv';
import Datastore from '../types/Datastore';
import User from '../types/User';
import Service from '../types/Service';

// constants
const API_URL = API_ADMIN;

export default class AdminService extends ApiService {
    /**
     * Get list of Data Stores.
     */
    static async getDataStores(): Promise<Datastore[]> {
        const response = await this.request('GET', `datastores`);
        return response.clusters.map((cluster: any) => new Datastore(cluster));
    }

    /**
     * Get list of users.
     */
    static async getUsers(): Promise<User[]> {
        const response = await this.request('GET', `users`);
        return response.users.map((user: any) => new User(user));
    }

    /**
     * Get list of nodes.
     */
    static async getNodes(datastoreID: string): Promise<Service[]> {
        const response = await this.request(
            'GET',
            `datastores/${datastoreID}/nodes`
        );
        const db_nodes = response.nodes.db_nodes;
        return db_nodes.map((node: any) => {
            const tempNode = Object.assign(node, node.host);
            delete tempNode.host;
            return new Service(tempNode);
        });
    }

    static async deleteDataStore(datastoreID: string): Promise<void> {
        await this.request('DELETE', `datastores/${datastoreID}`);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
