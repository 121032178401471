import ApiService from './ApiService';
import { API_AUTH } from '../core/CcxEnv';
import Credentials from '../types/Credentials';

// constants
const API_URL = API_AUTH;

export default class AuthorizationService extends ApiService {
    /**
     * Get credentials
     * @param {string} dataStoreUuid
     */
    static async getAllCredentials(): Promise<any> {
        const response = await this.request('GET', `cred/clients`);
        return response.clients.map((r: any) => new Credentials(r));
    }

    /**
     * Add a new Credentials to the current user in session.
     * @param {any} credentialsSettings
     */
    static async addCredentials({
        description,
        expirationTime,
    }: any): Promise<any> {
        const requestData = {
            data: {
                description,
                expires_in_hours: expirationTime,
            },
        };
        const res = await this.request('POST', `cred/clients`, requestData);
        return res;
    }

    /**
     * revoke a Credentials .
     * @param {string} client_id
     */
    static async revokeCredentials(clientId: string): Promise<any> {
        const requestData = {
            data: {
                client_id: clientId,
            },
        };
        return await this.request('PATCH', `cred/revoke`, requestData);
    }

    /**
     * disable a Credentials .
     * @param {string} client_id
     */
    static async disableCredentials(clientId: string): Promise<any> {
        const requestData = {
            data: {
                client_id: clientId,
            },
        };
        return await this.request('PATCH', `cred/disable`, requestData);
    }

    /**
     * enable a Credentials .
     * @param {string} client_id
     */
    static async enableCredentials(clientId: string): Promise<any> {
        const requestData = {
            data: {
                client_id: clientId,
            },
        };
        return await this.request('PATCH', `cred/enable`, requestData);
    }

    /**
     * remove a Credentials .
     * @param {string} client_id
     */
    static async removeCredentials(clientId: string): Promise<any> {
        return await this.request('DELETE', `cred/clients/${clientId}`);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
