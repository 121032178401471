import { ReactElement, useEffect, useState } from 'react';
import { Alert, Col, Menu, Row } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import Service from '../../../../types/Service';
import AppGridTable from '../../../ccx/common/AppGridTable';
import styles from './DataStoreServices.module.less';
import ServiceConnectionInformation from './ServiceConnectionInformation';
import ServiceInformation from './ServiceInformation';
import ServiceStatus from './ServiceStatus';
import { useParams } from 'react-router';
import useDataStoreServices from '../../../../core/hooks/useDataStoreServices';
import LazyLoader from '../../../LazyLoader';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import AppEmpty from '../../../AppEmpty';
import Deployments from '../../../../types/Deployments';
import Helpers from '../../../../services/Helpers';
import { Dropdown } from 'antd';
import { Button } from 'antd';
import CcxContextualMenuIcon from '../../../ccx/icons/CcxContextualMenuIcon';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import Job, { STATUS_RUNNING } from '../../../../types/Job';
import DotLabelStatus from '../../../ccx/common/DotLabelStatus';
import { Tooltip } from 'antd';
import SmallContainer from '../../../ccx/common/SmallContainer';
import PromoteReplicaButton from './PromoteReplicaButton';
import ServiceResources from './ServiceResources';
import ExtendNodeStorageModal from './DataStoreExtendStorageModal';
import CcxSectionHeader from '../../../ccx/common/CcxSectionHeader';
import ScaleNodeModal from './DataStoreScaleNodeModal';
import { getNonErroredJobs, sortNodesByRole } from '../../../../core/helpers';
import { getAllDataStores } from '../../../../slices/datastores.slice';
import { useAppDispatch } from '../../../../redux/hooks';
import DataStoreOverviewAccessToServices from '../overview/DataStoreOverviewAccessToServices';
import RepairNodeButton from './RepairNodeButton';
import ConnectionAssistant from './ConnectionAssistant';
import DataStoreNodeConfiguration from './DataStoreNodeConfiguration';

interface UrlProps {
    dataStoreUuid?: string;
    projectUuid: string;
}

interface Props extends CcxComponentProps {
    dataStores?: Deployments;
    setDbCount: Function;
    switchTab: Function;
}

interface ActionMenuProps {
    service: Service;
    onClick: (e: any) => void;
    showPromote: boolean;
    showRepair: boolean | undefined;
    disabled: boolean;
}

function ActionMenu({
    service,
    onClick,
    showPromote,
    showRepair,
    disabled,
}: ActionMenuProps) {
    return (
        <Menu onClick={onClick}>
            {showPromote && (
                <Menu.Item key="promote" disabled={disabled}>
                    <PromoteReplicaButton service={service} />
                </Menu.Item>
            )}
            {showRepair && (
                <Menu.Item key="repair" disabled={disabled}>
                    <RepairNodeButton service={service} />
                </Menu.Item>
            )}
        </Menu>
    );
}

function DataStoreServices({
    switchTab,
    dataStores,
    setDbCount,
    testId = 'DataStoreServices',
}: Props): ReactElement {
    const { dataStoreUuid, projectUuid } = useParams<UrlProps>();
    const currentPath = `/projects/${projectUuid}/data-stores/${dataStoreUuid}/services`;
    const dispatch = useAppDispatch();

    const [dataStore, setDataStore] = useState<DeploymentsItem | undefined>(
        undefined
    );
    const [isScalingModalVisible, setIsScalingModalVisible] =
        useState<boolean>(false);
    const { services, refresh, loading, updating } = useDataStoreServices({
        dataStoreUuid,
    });
    const [isNodesAvailable, setIsNodesAvailable] = useState<boolean>(false);
    const { jobs } = useDataStoreJobs({
        dataStoreUuid,
    });
    const [intervalId, setIntervalId] = useState<any>([]);

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    const [dbRows, setDbRows] = useState<any[]>([]);
    const [lbRows, setLbRows] = useState<any[]>([]);

    const dbColumns = [
        { title: 'Node information', span: 4 },
        { title: 'Role / Permissions', span: 5 },
        { title: 'Resources', span: 13 },
    ];

    const lbColumns = [
        { title: 'Node information', span: 5 },
        { title: 'Status', span: 3 },
        { title: 'Connection information', span: 15 },
    ];

    const startStoreInterval = () => {
        const id = setInterval(async () => {
            dispatch(getAllDataStores());
        }, 10000);
        setIntervalId((prevIds: any) => [...prevIds, id]);
    };

    const clearStoreInterval = () => {
        intervalId.forEach((id: any) => clearInterval(id));
    };

    useEffect(() => {
        const hasRunningJob = jobs?.some(
            (job: any) => job.status === STATUS_RUNNING
        );

        if (!hasRunningJob) {
            clearStoreInterval();
        }
    }, [jobs]);

    useEffect(() => {
        if (window.location.pathname !== currentPath) {
            clearStoreInterval();
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refresh && (await refresh());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (dataStores && dataStoreUuid) {
            const currentDataStore = dataStores?.getByUuid(dataStoreUuid);
            if (currentDataStore) setDataStore(currentDataStore);
        }
    }, [dataStores, dataStoreUuid]);

    useEffect(() => {
        setDbCount && setDbCount(dbRows.length);
    }, [dbRows]);

    useEffect(() => {
        if (services && dataStore) {
            const sortedDbServices = sortNodesByRole(services.dbServices);
            const tempDBRows = sortedDbServices?.map((s: Service) => {
                function handleMenuOptionClick(e: any) {
                    if (e.key === 'settings') {
                    }
                }

                const showPromote = s.isRoleReplica() && !s.isStatusError();

                const showRepair =
                    !(
                        dataStore?.isMSSQL() &&
                        dataStore.clusterType.toLowerCase() === 'mssql_single'
                    ) &&
                    dataStore?.clusterStatus?.isStatusStartedOrDegraded() &&
                    (s.isStatusError() ||
                        s.getStatus() === Service.CMON_STATUS_UNKNOWN);

                const menuDisabled =
                    !(showPromote || showRepair) ||
                    (s.isRolePrimary() && dataStore?.isSafespring()) ||
                    pendingJobs?.length > 0 ||
                    !dataStore?.operable;

                return (
                    dataStore && {
                        borderAlert: s.isStatusError(),
                        statusOverlayText: s.getStatus(),
                        columns: [
                            {
                                content: (
                                    <ServiceInformation
                                        dataStore={dataStore}
                                        service={s}
                                        testId={`${testId}${s.getServiceUuid()}ServiceInformation`}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <div>
                                        {`${Helpers.capitalizeFirstLetter(
                                            s.getRoleName()
                                        )} / ${Helpers.capitalizeFirstLetter(
                                            s.getPermisiion()
                                        )}`}
                                    </div>
                                ),
                                span: 5,
                            },
                            {
                                content: (
                                    <ServiceResources
                                        service={s}
                                        dataStore={dataStore}
                                    />
                                ),
                                span: 13,
                            },
                            {
                                align: 'center',
                                content: (
                                    <Dropdown
                                        className={
                                            styles.DataStoresDropdownMenu
                                        }
                                        data-testid={`${testId}${s.getServiceUuid()}Menu`}
                                        overlay={
                                            <ActionMenu
                                                disabled={
                                                    pendingJobs?.length > 0 ||
                                                    !dataStore?.operable
                                                }
                                                service={s}
                                                onClick={handleMenuOptionClick}
                                                showPromote={showPromote}
                                                showRepair={showRepair}
                                            />
                                        }
                                        placement="bottomRight"
                                        trigger={['click']}
                                        disabled={menuDisabled}
                                    >
                                        <Button
                                            data-testid={`${testId}${s.getServiceUuid()}MenuButton`}
                                            icon={
                                                <CcxContextualMenuIcon
                                                    testId={`${testId}${s.getServiceUuid()}MenuButtonIcon`}
                                                />
                                            }
                                        />
                                    </Dropdown>
                                ),
                                span: 2,
                            },
                        ],
                        disabled: s.isStarting(),
                    }
                );
            });

            if (jobs) {
                const runningJobs = jobs?.filter(
                    (job: Job) => job.isStatusRunning() && job.isAddNode()
                );

                const statusComponent = (
                    <Tooltip
                        placement="bottom"
                        title={'Deployment is running and will be ready soon'}
                    >
                        <DotLabelStatus
                            type="info"
                            label={'Deploying...'}
                            glow={updating}
                            testId={`${testId}StatusComponent`}
                        />
                    </Tooltip>
                );

                const runningRows = runningJobs.map((job: Job) => {
                    return (
                        dataStore && {
                            borderAlert: false,
                            statusOverlayText: '',
                            columns: [
                                {
                                    content: (
                                        <ServiceInformation
                                            dataStore={dataStore}
                                            service={services.dbServices[0]}
                                            deploying={true}
                                        />
                                    ),
                                    span: 5,
                                },
                                {
                                    content: (
                                        <SmallContainer
                                            testId={testId}
                                            topChildren={statusComponent}
                                        />
                                    ),
                                    span: 3,
                                },
                                {
                                    content: <LazyLoader type="row" rows={1} />,
                                    span: 4,
                                },
                                {
                                    content: <LazyLoader type="row" rows={1} />,
                                    span: 10,
                                },
                                {
                                    align: 'center',
                                    content: (
                                        <Dropdown
                                            className={
                                                styles.DataStoresDropdownMenu
                                            }
                                            data-testid={`${testId}${services.dbServices[0].getServiceUuid()}Menu`}
                                            overlay={null as any}
                                            placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                data-testid={`${testId}${services.dbServices[0].getServiceUuid()}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${services.dbServices[0].getServiceUuid()}MenuButtonIcon`}
                                                    />
                                                }
                                            />
                                        </Dropdown>
                                    ),
                                    span: 2,
                                },
                            ],
                            disabled: true,
                        }
                    );
                });
                setDbRows([...runningRows, ...tempDBRows]);
            } else {
                setDbRows(tempDBRows);
            }

            setLbRows(
                services.lbServices?.map((s: Service) => {
                    return (
                        dataStore && {
                            borderAlert: s.isStatusError(),
                            statusOverlayText: s.getStatus(),
                            columns: [
                                {
                                    content: (
                                        <ServiceInformation
                                            dataStore={dataStore}
                                            service={s}
                                            type="lb"
                                            testId={`${testId}${s.getServiceUuid()}ServiceInformation`}
                                        />
                                    ),
                                    span: 5,
                                },
                                {
                                    content: (
                                        <ServiceStatus
                                            service={s}
                                            testId={`${testId}${s.getServiceUuid()}ServiceStatus`}
                                            updating={false}
                                        />
                                    ),
                                    span: 3,
                                },
                                {
                                    content: (
                                        <ServiceConnectionInformation
                                            dataStore={dataStore}
                                            service={s}
                                            type="lb"
                                            testId={`${testId}${s.getServiceUuid()}ServiceConnectionInformation`}
                                        />
                                    ),
                                    span: 16,
                                },
                            ],
                            disabled: s.isStarting(),
                        }
                    );
                })
            );
            setIsNodesAvailable(true);
        }
    }, [services, dataStore, updating, jobs]);

    const pendingJobs = getNonErroredJobs(jobs);

    return (
        <div className={styles.DataStoreServices} data-testid={testId}>
            <main>
                {pendingJobs?.length > 0 && (
                    <Alert
                        className={styles.DataStoreServicesJobRunningAlert}
                        message={
                            <span>
                                Jobs are in progress. Please wait for it to
                                finish before making any changes. For status
                                check{' '}
                                <span
                                    className={
                                        styles.DataStoreServicesSwitchToEventViewerLink
                                    }
                                    onClick={() => switchTab('eventViewer')}
                                >
                                    Event Viewer
                                </span>
                            </span>
                        }
                        showIcon
                        type="info"
                    />
                )}

                <CcxSectionHeader>
                    <>
                        {dataStore?.replica_url ||
                        dataStore?.primary_url ? undefined : (
                            <ConnectionAssistant
                                dataStoreUuid={dataStoreUuid}
                                deployments={dataStores}
                            />
                        )}

                        {dataStore && (
                            <ScaleNodeModal
                                datastore={dataStore}
                                services={services}
                                nodes={dbRows}
                                pendingJobs={pendingJobs}
                                refresh={refresh}
                                clearStoreInterval={clearStoreInterval}
                                startStoreInterval={startStoreInterval}
                                isButtonDisabled={isButtonDisabled}
                                setIsButtonDisabled={setIsButtonDisabled}
                                isScalingModalVisible={isScalingModalVisible}
                                setIsScalingModalVisible={
                                    setIsScalingModalVisible
                                }
                            />
                        )}
                        {dataStore?.hasStorage() && (
                            <ExtendNodeStorageModal
                                datastore={dataStore!}
                                pendingJobs={pendingJobs}
                                nodes={dbRows}
                                refresh={refresh}
                                clearStoreInterval={clearStoreInterval}
                                startStoreInterval={startStoreInterval}
                                isButtonDisabled={isButtonDisabled}
                                setIsButtonDisabled={setIsButtonDisabled}
                            />
                        )}
                    </>
                </CcxSectionHeader>

                {(dataStore?.replica_url || dataStore?.primary_url) && (
                    <DataStoreOverviewAccessToServices
                        dataStore={dataStore}
                        services={services}
                        showTitle={false}
                    />
                )}

                <Row
                    justify={'space-between'}
                    align={'middle'}
                    className={styles.AppTableHeadingBar}
                >
                    <Col style={{ paddingBottom: 8 }}>
                        <h4>Nodes</h4>
                    </Col>
                    <Col style={{ paddingBottom: 8 }}>
                        <DataStoreNodeConfiguration
                            setIsScalingModalVisible={setIsScalingModalVisible}
                            clearStoreInterval={clearStoreInterval}
                            dataStore={dataStore}
                            services={services}
                        />
                    </Col>
                </Row>

                {loading && !isNodesAvailable && <LazyLoader type="row" />}

                <div className={styles.AppGridTableWrapper}>
                    {isNodesAvailable && dbRows?.length > 0 ? (
                        <AppGridTable
                            columns={dbColumns}
                            rows={dbRows}
                            testId={`${testId}Databases`}
                        />
                    ) : (
                        !loading && (
                            <AppEmpty message="Nodes information is not available for this datastore." />
                        )
                    )}
                </div>

                {!dataStore?.isRedis() && lbRows?.length > 0 && (
                    <>
                        <h4>Load balancers</h4>
                        {loading && <LazyLoader type="row" />}
                        <AppGridTable
                            columns={lbColumns}
                            rows={lbRows}
                            testId={`${testId}LoadBalancers`}
                        />
                    </>
                )}
            </main>
        </div>
    );
}

export default DataStoreServices;
