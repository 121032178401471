import React, { ReactElement, useState, useEffect } from 'react';
import { notification, Modal, Row, Col, Popover } from 'antd';
import styles from './DbUsers.module.less';
import AppEmpty from '../AppEmpty';
import { Link, useParams } from 'react-router-dom';
import useDbUsers from '../../core/hooks/useDbUsers';
import useDatabases from '../../core/hooks/useDatabases';
import AppTable from '../AppTable';
import { InfoCircleOutlined, StopOutlined } from '@ant-design/icons';
import CopyToClipboardText from '../CopyToClipboardText';
import AddDbUser from './AddDbUser';
import AppConfirmDialog from '../AppConfirmDialog';
import DbUserService from '../../services/DbUserService';
import AppLoading from '../AppLoading';
import Deployments from '../../types/Deployments';
import ConnectionAssistant from './ConnectionAssistant';
import DbUser, { DEFAULT_DATABASE_USER } from '../../types/DbUser';
import Deployment from '../../types/Deployment';
import useDataStore from '../../core/hooks/useDataStore';
import CcxSectionHeader from '../ccx/common/CcxSectionHeader';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';
import { Tooltip } from 'antd';
import TypographyText from '../../tmp/TypographyText';
import './DbUser.less';
import { getAllDataStores } from '../../slices/datastores.slice';
import { useAppDispatch } from '../../redux/hooks';
import UpdateDbUserActionsMenu from './UpdateDbUserActionsMenu';
interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
}

type DbUsersProps = {
    deployments?: Deployments;
    deployment?: Deployment;
};

function DbUsers({ deployments }: DbUsersProps): ReactElement {
    const dispatch = useAppDispatch();
    const { dataStoreUuid, projectUuid } = useParams<UrlProps>();
    const { dataStore: deployment } = useDataStore(dataStoreUuid);
    const currentDeployment = deployments?.getByUuid(dataStoreUuid);
    const {
        dbUsers,
        loading: dbUsersLoading,
        refresh: dbUsersRefresh,
    } = useDbUsers(dataStoreUuid); // @TODO: Add refresh here
    const { databases, loading: databasesLoading } =
        useDatabases(dataStoreUuid);
    const [_, setDeleting] = useState<boolean>(false);
    const [dbUsersData, setDbUsersData] = useState<any>([]);
    const [hosts, setHosts] = useState<any>(undefined);
    const [dbService, setDbService] = useState('');
    const [usersHosts, setUsersHosts] = useState<string[]>([]);

    useEffect(() => {
        if (deployment) {
            setHosts(deployment.getHosts());
        }
    }, [deployment]);

    useEffect(() => {
        setDbService(currentDeployment?.getServiceName() || '');

        if (dbUsers && dbUsers.accounts) {
            const data = dbUsers.accounts.map((u: DbUser) => {
                return {
                    ...u,
                    uniqueGrants: u.getUniqueGrants(),
                };
            });
            const sortedUsers = [...data].sort((a, b) =>
                a.username.localeCompare(b.username)
            );
            setDbUsersData(sortedUsers);
        }
    }, [dbUsers, currentDeployment]);

    useEffect(() => {
        if (dbUsers && dbUsers.accounts) {
            setUsersHosts(
                dbUsers.accounts?.map((u: DbUser) => {
                    return u.username;
                })
            );
        }
    }, [dbUsers]);

    const usersColumns: (
        | {
              width: number;
              title: JSX.Element | string;
              render: (text: string, record: any) => JSX.Element;
              key: string;
              fixed?: string;
          }
        | {
              title: string;
              render: (text: string, record: any) => JSX.Element;
              key: string;
              ellipsis: boolean;
          }
    )[] = [
        {
            title: 'Account',
            key: 'user',
            width: 100,
            render: (text: string, record: any) => {
                return currentDeployment?.isMariaDb() ||
                    currentDeployment?.isPercona() ? (
                    <CopyToClipboardText text={record.username} />
                ) : (
                    <CopyToClipboardText text={`${record.username}`} />
                );
            },
        },
        {
            title: currentDeployment?.isRedis() ? 'ACL' : 'Privileges',
            key: currentDeployment?.isRedis() ? 'ACL' : 'privileges',
            width: 400,
            ellipsis: true,
            render: (text: string, record: any) => {
                const privileges = record?.grants
                    ?.split(';')
                    ?.map((privilege: string) => {
                        if (privilege.indexOf(':') === -1) {
                            return {
                                db: '*.*',
                                privileges: privilege.split(',').sort(),
                            };
                        }
                        const [database, privileges] = privilege.split(':');

                        return {
                            db: database,
                            privileges: privileges.split(',').sort(),
                        };
                    });

                return (
                    <Row gutter={[16, 16]}>
                        {privileges?.map((priv: any) => {
                            return (
                                <Col
                                    span={privileges.length > 1 ? 8 : 24}
                                    className={styles.DbUsersSegmentedData}
                                >
                                    <div
                                        className={
                                            styles.DbUsersSegmentedFirstItem
                                        }
                                    >
                                        {priv.db}
                                    </div>
                                    <Popover
                                        content={
                                            <Row>
                                                {priv.privileges.filter(
                                                    (priv: string) =>
                                                        priv !== ''
                                                ).length
                                                    ? priv.privileges?.map(
                                                          (item: string) => (
                                                              <Col
                                                                  span={
                                                                      privileges.length >
                                                                      1
                                                                          ? 8
                                                                          : 24
                                                                  }
                                                                  className={
                                                                      styles.DbUserPopoverCol
                                                                  }
                                                              >
                                                                  <ul>
                                                                      <li
                                                                          key={
                                                                              item
                                                                          }
                                                                      >
                                                                          {item.trim()}
                                                                      </li>
                                                                  </ul>
                                                              </Col>
                                                          )
                                                      )
                                                    : currentDeployment?.isMSSQL() &&
                                                      `Sysadmin`}
                                            </Row>
                                        }
                                        title={
                                            <span
                                                className={
                                                    styles.DBUserPopoverTitle
                                                }
                                            >
                                                {priv.db === '*.*'
                                                    ? 'Global Privileges'
                                                    : 'Privileges'}
                                            </span>
                                        }
                                        destroyTooltipOnHide={true}
                                        arrowPointAtCenter={false}
                                        overlayInnerStyle={{
                                            padding: 0,
                                            borderRadius: 16,
                                        }}
                                        overlayStyle={{
                                            maxWidth: 400,
                                            minWidth: 320,
                                        }}
                                        overlayClassName="DbUserPopover"
                                    >
                                        <div
                                            className={
                                                styles.DbUsersSegmentedSecondItem
                                            }
                                        >
                                            {priv.privileges.filter(
                                                (priv: string) => priv !== ''
                                            ).length
                                                ? priv.privileges.join(', ')
                                                : currentDeployment?.isMSSQL() &&
                                                  `Sysadmin`}
                                        </div>
                                    </Popover>
                                </Col>
                            );
                        })}
                    </Row>
                );
            },
        },
    ];
    if (currentDeployment?.isPercona()) {
        usersColumns.push({
            title: 'Authentication Plugin',
            key: 'authPlugin',
            ellipsis: true,
            width: 100,
            render: (text: string, record: any) => {
                return record.authPlugin;
            },
        });
    }

    usersColumns.push({
        title: <TypographyText nowrap={true}>Actions</TypographyText>,
        key: 'actions',
        width: 90,
        render: (text: string, record: any) => {
            const onConfirmDelete = () => {
                return new Promise(async (resolve, reject) => {
                    setDeleting(true);

                    notification.open({
                        message: 'Remove account',
                        description: 'The account will be removed soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    Modal.destroyAll();

                    try {
                        const data = {
                            uuid: dataStoreUuid,
                            dbUsername: record.username,
                            dbHost: record.hostAllow,
                        };
                        await DbUserService.deleteUser(data);

                        dbUsersRefresh && (await dbUsersRefresh());
                        dispatch(getAllDataStores());

                        notification.open({
                            message: 'Remove Account',
                            description: 'Account successfully removed',
                            icon: (
                                <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
                            ),
                        });

                        setDeleting(false);
                        resolve(null);
                    } catch (e) {
                        notification.open({
                            message: 'Remove Account',
                            description: `There was an error removing the account. ${e}`,
                            icon: (
                                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                            ),
                        });

                        console.error(e);

                        setDeleting(false);
                        reject();
                    }
                }).catch(() => console.log('Oops errors!'));
            };

            return (
                <div className={styles.DbUsersOptionsColumn}>
                    {record.username === DEFAULT_DATABASE_USER ? (
                        <Tooltip
                            placement="top"
                            title="Default user cannot be deleted or changed"
                        >
                            <StopOutlined className={styles.DbUsersStop} />
                        </Tooltip>
                    ) : currentDeployment?.isRedis() ? (
                        <UpdateDbUserActionsMenu
                            dbUsersRefresh={dbUsersRefresh}
                            uuid={dataStoreUuid}
                            user={record}
                            databases={databases?.databases}
                            deployment={currentDeployment}
                            onConfirmDelete={onConfirmDelete}
                        />
                    ) : (
                        <AppConfirmDialog
                            critical={true}
                            onOk={onConfirmDelete}
                            content="The account will be removed and will not be able to connect to the database."
                            actionIcon={<CcxIconDeleteOutlined />}
                        />
                    )}
                </div>
            );
        },
    });

    return (
        <section className={styles.DbUsers}>
            <CcxSectionHeader>
                <div className={styles.ConnectionHeaderButtons}>
                    <ConnectionAssistant
                        databases={databases?.databases}
                        users={usersHosts}
                        hosts={hosts}
                        service={dbService}
                        datastore={currentDeployment as any}
                        disabled={dbUsersLoading || databasesLoading}
                    />
                    {!databasesLoading && (
                        <AddDbUser
                            onSuccess={dbUsersRefresh}
                            uuid={dataStoreUuid}
                            databases={databases?.databases}
                            currentDeployment={currentDeployment}
                        />
                    )}
                </div>
            </CcxSectionHeader>
            <h4 className={styles.DbUsersLabel}>Users</h4>
            {dbUsersLoading ? (
                <AppLoading />
            ) : dbUsers?.accounts?.length ? (
                <AppTable
                    columns={usersColumns}
                    data={dbUsersData}
                    rowKey="uniqueKey"
                    expandable={false}
                    footer={() => (
                        <>
                            <InfoCircleOutlined /> Accounts are allowed to
                            connect from trusted sources controlled by the{' '}
                            <Link
                                to={`/projects/${projectUuid}/data-stores/${dataStoreUuid}/firewall`}
                            >
                                Firewall
                            </Link>
                        </>
                    )}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                />
            ) : (
                <AppEmpty message="There are no users added yet" />
            )}
        </section>
    );
}

export default DbUsers;
